import { Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { setCookiePermission } from 'app/store/persistorSlice';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { default as CookiesService } from '../../../../services/CookiesService';
import LoadingNireeka from '../../LoadingNireeka';
import CookieModalMainComponent from './CookieModalMainComponent';

const PrivacyAgreementModal = dynamic(
  () => import('../PrivacyAgreementModal'),
  {
    ssr: false,
  }
);

const CookieModalManagePreferences = dynamic(
  () => import('./CookieModalManagePreferences'),
  {
    ssr: false,
    loading: () => (
      <div className='flex justify-center py-32'>
        <LoadingNireeka className='w-10 h-10 mx-auto border-black' />
      </div>
    ),
  }
);

const newCookiesList = [
  /* 'klaviyo' */
];

const CookieModal = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const [isShowPreferences, setIsShowPreferences] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const privacyDisclosure = useDisclosure(false);
  const cookiePermissions = useSelector(
    (state) => state.persistor.cookiePermissions
  );

  useEffect(() => {
    // Check if the Cookie_Seen cookie is not set to true
    if (CookiesService.get('Cookie_Seen') !== 'true') {
      // If the modal is not already open, open it
      if (!opened) open();

      // Loop through all the cookie permissions
      Object.keys(cookiePermissions).forEach((permission) => {
        // If the permission is set to true, setCookiePermission to 'onlyNecessary'
        // ! do not change 'true' value !
        if (cookiePermissions[permission] === true) {
          dispatch(
            setCookiePermission({
              // Set the type of the setCookiePermission action to 'onlyNecessary'
              type: 'onlyNecessary',
            })
          );
        }
      });
    }
  }, [cookiePermissions]);

  useEffect(() => {
    // Check if the Cookie_Seen cookie is not set to true
    if (CookiesService.get('Cookie_Seen') !== 'true') return;

    /**
     * Check if there are any missing cookies.
     */
    const hasMissingCookies = newCookiesList.some(
      (cookie) => cookiePermissions[cookie] === 'undefined' // ! do not change 'undefined' value !
    );

    // If there are missing cookies, open the modal
    if (hasMissingCookies) open();
  }, [cookiePermissions]);

  const onAllowAll = () => {
    CookiesService.set('Cookie_Seen', 'true', {
      maxAge: 1000 * 60 * 60 * 24 * 30, // one month
      secure: true,
      path: '/',
    });
    CookiesService.set('NEXT_LOCALE_CODE', router.locale, {
      maxAge: 1000 * 60 * 60 * 24 * 30,
      path: '/',
    });
    dispatch(
      setCookiePermission({
        type: 'allowAll',
      })
    );
    close();
  };

  const onOnlyNecessary = () => {
    CookiesService.set('Cookie_Seen', 'true', {
      maxAge: 1000 * 60 * 60 * 24 * 30, // one month
      secure: true,
      path: '/',
    });
    CookiesService.set('NEXT_LOCALE_CODE', router.locale, {
      maxAge: 1000 * 60 * 60 * 24 * 30,
      path: '/',
    });
    dispatch(
      setCookiePermission({
        type: 'onlyNecessary',
      })
    );
    close();
  };

  const onManagePreferences = () => {
    setIsShowPreferences(true);
  };

  return (
    <>
      <Modal
        opened={opened}
        centered
        // fullScreen={!isDesktopView}
        closeOnClickOutside={false}
        closeOnEscape={false}
        withCloseButton={false}
        radius='md'
        size='lg'
        title='Enhance Your Experience with Cookies'
        zIndex={1002}
        classNames={{
          title: '!text-2xl !font-semibold',
          content: '!max-h-none',
          root: '[&_*]:!font-["Open Sans"]',
          inner: `!overflow-y-auto`,
        }}
        aria-hidden
      >
        <div className='h-full'>
          {isShowPreferences ? (
            <CookieModalManagePreferences
              setIsShowPreferences={setIsShowPreferences}
              closeModal={close}
              onAllowAll={onAllowAll}
            />
          ) : (
            <CookieModalMainComponent
              privacyDisclosure={privacyDisclosure}
              onAllowAll={onAllowAll}
              onOnlyNecessary={onOnlyNecessary}
              onManagePreferences={onManagePreferences}
            />
          )}
        </div>
      </Modal>
      {privacyDisclosure[0] && (
        <PrivacyAgreementModal privacyDisclosure={privacyDisclosure} />
      )}
    </>
  );
};

export default CookieModal;
