import PrimaryButton from '../../buttons/PrimaryButton';
import SecondaryButton from '../../buttons/SecondaryButton';

const CookieModalMainComponent = ({
  privacyDisclosure,
  onAllowAll,
  onOnlyNecessary,
  onManagePreferences,
}) => {
  return (
    <div>
      <p className='pt-5 pb-10 leading-7'>
        <span className='font-semibold'>Nireeka</span> uses cookies to store
        browsing data, some for essential website functions and others to
        personalize your experience with targeted marketing and improved
        performance. Choose{' '}
        <span className='font-semibold'>&ldquo;Allow all&ldquo;</span> for a
        fully personalized journey,{' '}
        <span className='font-semibold'>&ldquo;Manage preferences&ldquo;</span>{' '}
        for customization, or{' '}
        <span className='font-semibold'>&ldquo;Only necessary&ldquo;</span> to
        limit cookies. See{' '}
        <span
          className='text-lg font-medium text-gray-900 underline cursor-pointer underline-offset-4 hover:text-black whitespace-nowrap'
          onClick={() => privacyDisclosure[1].open()}
        >
          Privacy Agreement
        </span>{' '}
        for more details.
      </p>
      <div className='flex flex-col-reverse gap-4 md:flex-row'>
        <SecondaryButton
          id='managePreferencesBtn'
          className='whitespace-nowrap'
          onClick={onManagePreferences}
          fullWidth
        >
          Manage preferences
        </SecondaryButton>
        <SecondaryButton
          id='onlyNecessaryCookiesBtn'
          className='whitespace-nowrap'
          onClick={onOnlyNecessary}
          fullWidth
        >
          Only necessary
        </SecondaryButton>
        <PrimaryButton
          id='allowAllCookiesBtn'
          className='whitespace-nowrap'
          onClick={onAllowAll}
          fullWidth
        >
          Accept all
        </PrimaryButton>
      </div>
    </div>
  );
};

export default CookieModalMainComponent;
